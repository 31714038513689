import { Injectable } from '@angular/core';
import { Empresa } from '../api/dto/empresa';
import { Administradora } from '../api/dto/administradora';
import { Permissao } from '../api/dto/permissao';
import { MenuDTO } from '../api/dto/menu-dto';
import { AuthService } from './auth.service';
import { Usuario } from '../api/dto/usuario';
import { Caixa } from '../api/dto/caixa';

const TOKEN_KEY = 'auth-token';
const REFRESHTOKEN_KEY = 'auth-refreshtoken';
const USER_KEY = 'auth-user';
const LOGGED_USER_KEY = 'auth-logged-user';
const EMPRESA_KEY = 'empresa-selecionada';
const ADMINISTRADORA_KEY = 'administradora-selecionada'
const PERMISSOES_KEY = 'permissoes'
const MENUS_KEY = 'menus'
const CAIXA_SELECIONADO_KEY = 'caixa-selecionado-key'

@Injectable({
  providedIn: 'root'
})
export class UtilStorageService {

  constructor(private authService: AuthService) { }

  signOut(): void {
    window.sessionStorage.clear();
    this.authService.logout().subscribe({
      next: (data: any) => { },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);

    const user = this.getUser();
    if (user.id) {
      this.saveUser({ ...user, accessToken: token });
    }
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveRefreshToken(token: string): void {
    window.sessionStorage.removeItem(REFRESHTOKEN_KEY);
    window.sessionStorage.setItem(REFRESHTOKEN_KEY, token);
  }

  public getRefreshToken(): string | null {
    return window.sessionStorage.getItem(REFRESHTOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.saveLoggedUser(user);
  }

  public saveLoggedUser(user: any): void {
    window.sessionStorage.removeItem(LOGGED_USER_KEY);
    window.sessionStorage.setItem(LOGGED_USER_KEY, JSON.stringify(user.usuario));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  public getLoggedUser(): Usuario {
    const user = window.sessionStorage.getItem(LOGGED_USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  public getPermissoes(): Permissao[] {
    const permissoes = window.sessionStorage.getItem(PERMISSOES_KEY);
    if (permissoes) {
      return JSON.parse(permissoes);
    }
    return [];
  }

  public savePermissoes(permissoes: Permissao[]): void {
    window.sessionStorage.removeItem(PERMISSOES_KEY);
    window.sessionStorage.setItem(PERMISSOES_KEY, JSON.stringify(permissoes));
  }

  public saveEmpresa(empresa: Empresa): void {
    window.sessionStorage.removeItem(EMPRESA_KEY);
    window.sessionStorage.setItem(EMPRESA_KEY, JSON.stringify(empresa));
  }

  public saveAdministradora(administradora: Administradora): void {
    window.sessionStorage.removeItem(ADMINISTRADORA_KEY);
    window.sessionStorage.setItem(ADMINISTRADORA_KEY, JSON.stringify(administradora));
  }

  public getEmpresa(): Empresa {
    const empresa = window.sessionStorage.getItem(EMPRESA_KEY);
    if (empresa) {
      return JSON.parse(empresa);
    }
    return {};
  }

  public saveCaixaSelecionado(caixa: Caixa): void {
    window.sessionStorage.removeItem(CAIXA_SELECIONADO_KEY);
    window.sessionStorage.setItem(CAIXA_SELECIONADO_KEY, JSON.stringify(caixa));
  }

  public getCaixaSelecionado(): Caixa | undefined {
    const caixa = window.sessionStorage.getItem(CAIXA_SELECIONADO_KEY);
    if (caixa) {
      return JSON.parse(caixa);
    }
    return undefined;
  }

  public limparCaixaSelecionado() {
    window.sessionStorage.removeItem(CAIXA_SELECIONADO_KEY);
  }

  public getAdministradora(): Administradora {
    const administradora = window.sessionStorage.getItem(ADMINISTRADORA_KEY);
    if (administradora) {
      return JSON.parse(administradora);
    }
    return {};
  }

  public saveMenus(menus: MenuDTO[]): void {
    window.sessionStorage.removeItem(MENUS_KEY);
    window.sessionStorage.setItem(MENUS_KEY, JSON.stringify(menus));
  }

  public getMenus(): MenuDTO[] | null {
    const menus = window.sessionStorage.getItem(MENUS_KEY);
    if (menus) {
      return JSON.parse(menus);
    }
    return null;
  }
}
